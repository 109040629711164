import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { pageRouteConstants } from '../models/ui-constants';

@Injectable()
export class DirectAccessGuard  {
  constructor(private router: Router) {
    //example:     router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
    //       console.log(event);
    //     });
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    // If the previous URL was blank, then the user is directly accessing this page
    if (this.router.url === '/') {
      return this.router.createUrlTree([pageRouteConstants.ROUTE_CLASSES]); // Navigate away to some other page.

      //Obsolete method to do the same thing
      //this.router.navigateByUrl(['classes']);  Returns a promise
      //return false;
    }
    return true;
  }
}
