export enum DrillDetailActionEnum {
  DELETE,
  INSERT,
}

export enum EmployeeCheckTypeEnum {
  UNKNOWN = 0,
  VALID = 1,
  DUPLICATE = 2,
  NOTFOUND = 3,
  CLASSASSIGNEDSTUDENT = 4,
  HRSTATUS = 5,
	POI = 6,
}

export enum HierarchyLevel {
  CATEGORY = 1, // RQ,Emergency Exits, Emergency Equipment
  DRILL = 2, // Boeing L1 - Normal, Boeing L1 - Emergency
  COMPONENT = 3, // OPEN,CLOSE,ARM
  TASK = 4, // Present, covering emergency handle
  DEVIATION = 5, // Deviation
}

export enum MessageReasonCode {
  UNDEFINED = 0,
  CLASSCOMPLETE = 1,
  DRILLREMEDIATION = 2,
}

export enum PropertyState {
  COMPLETE = 1,
  INCOMPLETE = 2,
  UNCONFIRMED = 3,
}

export enum TraineeType {
  OTHER = 1,
  MANAGER = 2,
}

export enum TrainingDrillStatus {
  NOTAPPLICABLE = 0,
  SUCCESS = 1,
  FAILURE = 2,
  RETRY = 3,
  NOATTEMPT = 4,
}

export enum TrainingStatus {
  IDLE = 1,
  INDRILL = 2,
  ABSENT = 3,
  SUCCESSFUL = 4,
  UNSUCCESSFUL = 5,
  INSESSION = 6,
  COMPLETE = 7,
  SCHEDULED = 8,
  RETRY = 9,
  NOATTEMPT = 10,
  READY = 11,
  INCOMPLETE = 12,
  ANY = 999,
}

export enum TrainingType {
  USECLASSDEFAULT = 0,
  RT = 1,
  RQ = 2,
  IT = 3,
  GR = 4,
  OE = 5,
}

//ISOLATED AS SEPARATE FILE IN C# MODELS as constants
export enum ErrorConstants {
  ERRCODE_STALE_ROSTER_DATA = -1,
  ERRCODE_STALE_INSTRUCTOR_ID = -2,
  ERRCODE_WARNING = -3,
  ERRCODE_ROSTER_EDIT_DATA = -4,
}

//Training status
// 1	1	'Idle' Student Roster Status  (checkmark)
// 2	2	'InDrill'  Student Roster Status
// 3	3	'Absent' - Student Roster Status
// 4	4	'Successful' - Student Roster CHIP /Class / Student Roster Status
// 5	5	'Unsuccessful' - Student Roster CHIP / Class / Student Roster Status
// 6	6	'In Session' - Class
// 7	7	'Complete' - Class
// 8	8	'Scheduled' - Class
// (ADD) 9 9 'RETRY' - Student Roster CHIP
// (ADD) 10 10 'NO Attempt' - Student Roster CHIP
// (ADD) 11 11 'Ready' Student Roster Status (Plus)

//Drill status
// Green = Success
// Orange = Failed means one attempt was made and failed.
// Red = Failed means 2 attempts were made and both failed – i.e. they have failed that drill and no more attempts can be made for that day. They will need to return to make up that drill in a later session.
// Grey = Not Started/Scheduled
// this.boxType = this.boxType.trim().toUpperCase();
// const mode =
// this.boxType == "SUCCESS"  ? 'greenSquare':
// (this.boxType == "FAILURE"  ? 'redSquare':
// (this.boxType == "RETRY" ? 'retrySquare' :
// (this.boxType == "NOATTEMPT" ? 'graySquare'
// ELSE : 'noSquare'
// )));
//Dummy data for demonstration until we add statuses to RosterInfo
// dummyTypeNOATTEMPT: string = 'NOATTEMPT';
// dummyTypeRETRY: string = 'RETRY';
// dummyTypeUSUCC: string = 'FAILURE';
// dummyTypeSUCC: string = 'SUCCESS';
// dummyTypeBLANK: string = '';
