import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth-service/auth.service';
import { AuthenticationType } from '../models/ui-enums';

/**
 * Prevents unauthorized users from activating or loading protected paths and forces unauthenticated users into the authentication flow
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private authService: AuthService, private router: Router) {}

  canMatch(route: Route): boolean | UrlTree {
     if (this.isAuthorized(route.path ?? '')){
      return true;
    }
      // user is not authorized, redirect to login page
      this.router.navigateByUrl('auth');
      return false;
    }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.isAuthorized(state.url);
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.isAuthorized(state.url);
  }

  private isAuthorized(stateUrl: string): boolean {
    // If no valid authentication token, always route to auth page
    if (!this.authService.hasValidAuthenticationToken()) {
      this.router.navigateByUrl('auth');
    }

    // If authorized, allow navigation
    // Note : Subscribe is still required to set the authService property for use below.
    this.authService.isAuthorized$.subscribe((result) => {
      if (result === AuthenticationType.Success) {
        if (this.router.getCurrentNavigation()?.initialUrl === undefined) {
          // if prior navigation route was defined and current navigation is NOT, then force to prior navigation
          this.router.navigateByUrl(stateUrl);
        }
        return true;
      } else {
        return false;
      }
    });

    // Return authorization result from auth service here (true or false)
    // Note: On initial run, will be false (auth service checks on done yet)
    //   Subscribe above will pick up change to isAuthorized$ observable when available.
    //   Once set, the check of .isAuthorizedUser below will be true and allow navigation.
    //   The /auth component must also subscribe to isAuthorized$ to redirect to /training after guard returns true.
    return this.authService.isAuthorizedUser ? true : false;
  }
}
