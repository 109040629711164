/*
 * Friendly reminder!!
 * Any time functions should be centralized in ..\src\app\shared\time-utils.ts
 * Per third party support, We now generally consider Moment to be a legacy project in maintenance mode. It is not dead, but it is indeed done.
 * Once a better alternative is identified then all code changes will be occur in a single file
 */

import { MessageBanner, MessageType } from '../models/message-banner';
import { ErrorConstants } from '../models/shared/appEnums';
import { IErrorResponse } from '../models/error-response';
import { aagMessageConstants } from '../models/ui-constants';

/**
 * Determine if the argument is defined array (not null or undefined) with data
 *
 * @param arg the thing to be checked
 * @returns boolean, true indicating that the arg was not null or undefined, false indicating that it was null, undefined or empty
 */

export const isValidPopulatedArray = (object: any): Boolean => {
  let isValid = object && Array.isArray(object) && object.length > 0;
  return isValid;
};

/**
 * Determine if the argument is defined (not null or undefined)
 *
 * @param arg the thing to be checked
 * @returns boolean, true indicating that the arg was not null or undefined, false indicating that it was null or undefined
 */
export const isDefined = <T>(arg: T | null | undefined): arg is T => {
  return arg !== null && arg !== undefined;
};

/**
 * Converts an object and all of its nested properties into a flat object
 * Nested properties have their parent properties key prepended to avoid duplicate keys
 *
 * @param obj the object to be flattened
 * @param parentProperty an optional name of a parent property used during recursive calls to prepend the parent key to the child key
 * @returns a flattened object
 *
 * @example
 *
 * input:
 * {
 *    parentProp1: {
 *        childProp1: 'value',
 *        childProp2: 'value',
 *    },
 *    parentProp2: 'value'
 * }
 *
 * output:
 * {
 *    parentProp1ChildProp1: 'value',
 *    parentProp1ChildProp2: 'value',
 *    parentProp2: 'value'
 * }
 */
export const flattenObject = (obj: { [x: string]: unknown }, parentProperty?: string): Record<string, unknown> => {
  // create an empty object to hold the flattened values
  const flattened = {} as Record<string, unknown>;
  // iterate over the keys
  Object.keys(obj).forEach((key) => {
    // if a parentProperty was passed in the property name is the parentProperty + current key, otherwise just the key
    const propertyName = parentProperty ? parentProperty + key.charAt(0).toUpperCase() + key.slice(1) : key;
    // if the value for the current key is a non-null object, recursively flatten in using the current propertyName as the parentProperty
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key] as Record<string, unknown>, propertyName));
    } else {
      // the value for the current key was not an object, assign it to our new flattend object
      flattened[propertyName] = obj[key];
    }
  });
  return flattened;
};

/**
 * Converts a camel case string to a space delimited string with a capitalized first character
 *
 * @param value the string to be converted
 * @returns string with each capital letter replaced by a space and the lowercase equivalent, the first character will be capitalized
 *
 * @example
 * someValue => Some value
 * SomeValue => Some value
 */
export const camelCaseToSentence = (value: string): string => {
  // use regex to replace all capital letters with a space and the same character
  let result = value.replace(/([A-Z])/g, ' $1');
  // Convert the first character to capital letter and the rest to lowercase
  result = result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
  return result.trim();
};

// Add suffix based on number to indicate position 1-1st, 2-2nd, 3-3rd and so on.
//Used for Attempts Tab.
export function ordinalSuffix(i: number) {
  let j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
}

function isErrorResponse(jsonString: string) {
  try {
    let o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    if (o && typeof o === 'object') {
      return o;
    }
  } catch (e) {}

  return false;
}

export function setMessageBanner(err: any): MessageBanner {
  let messageBanner: MessageBanner;

  if (isErrorResponse(err)) {
    let errorResponse: IErrorResponse = JSON.parse(err);
    if (
      errorResponse.reasonCode == ErrorConstants.ERRCODE_STALE_ROSTER_DATA ||
      errorResponse.reasonCode == ErrorConstants.ERRCODE_WARNING
    ) {
      messageBanner = new MessageBanner(errorResponse.reasonMessage, MessageType.Warning);
    } else if (errorResponse.reasonCode == ErrorConstants.ERRCODE_STALE_INSTRUCTOR_ID) {
      messageBanner = new MessageBanner(errorResponse.reasonMessage, MessageType.NavigationDialog);
    } else messageBanner = new MessageBanner(aagMessageConstants.internalServerError, MessageType.Error);
  } else {
    messageBanner = new MessageBanner(aagMessageConstants.internalServerError, MessageType.Error);
  }
  return messageBanner;
}

export function firstLetterCapitalize(input: string): string {
  const words = input.split(' ');
  let output = words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1).toLowerCase();
    })
    .join(' ');
  return output;
}

// Basic parser for CSV strings to Array<string>
export function StringToArray(string: string, delimiter: string, stripWhiteSpace: boolean = true): Array<string> {
  let employeeIdArray: Array<string>;

  employeeIdArray = string.split(delimiter);
  if (stripWhiteSpace) {
    employeeIdArray = employeeIdArray.map((s) => s.trim());
  } else {
    employeeIdArray = employeeIdArray.map((s) => s);
  }

  return employeeIdArray;
}
