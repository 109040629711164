//Source: https://www.codemag.com/Article/1711021/Logging-in-Angular-Applications
import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';

export enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Fatal = 5,
  Off = 6,
}

// This is part of proposed revision ot LogTrace (and other) methods
// It is used to restrict logging to specific environments
// export enum EnvironmentLevel {
//   dev = 'dev',
//   test = 'test',
//   prod = 'prod',
// }

@Injectable({
  providedIn: 'root',
})
//
export class LogService {
  level: LogLevel = LogLevel.All;
  logWithDate: boolean = true;
  appInsights: ApplicationInsights;

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.instrKey,
        enableAutoRouteTracking: true, // option to log all route changes
      },
    });
    this.appInsights.loadAppInsights();
  }

  logPageView(name: string, url: string) {
    // option to call manually
    if (environment.logTarget == 'AppInsight') {
      this.appInsights.trackPageView({
        name: name,
        uri: url,
      });
    }
    if (environment.logTarget == 'Console') {
      this.writeToLog(name, LogLevel.Info, { url: url });
    }
  }

  logEvent(name: string, properties: any) {
    if (environment.logTarget == 'AppInsight') {
      this.appInsights.trackEvent({ name: name }, properties);
    }
    if (environment.logTarget == 'Console') {
      this.writeToLog(name, LogLevel.Info, properties);
    }
  }

  logMetric(name: string, average: number, properties: any) {
    if (environment.logTarget == 'AppInsight') {
      this.appInsights.trackMetric({ name: name, average: average }, properties);
    }
    if (environment.logTarget == 'Console') {
      this.writeToLog(name, LogLevel.Info, properties);
    }
  }

  logException(exception: Error, severityLevel?: number) {
    const exceptionMessage = exception.stack == null ? '' : exception.stack;
    if (environment.logTarget == 'AppInsight') {
      this.appInsights.trackException({
        exception: new Error(exceptionMessage),
        severityLevel: severityLevel,
        properties: { info: 'UI Exception', itemType: 'TypeScript' },
      });
    }
    if (environment.logTarget == 'Console') {
      this.writeToLog(exceptionMessage, LogLevel.Error, { exception: exception });
    }
  }

  logTrace(message: string, properties: any) {
    switch (environment.logTarget) {
      case 'AppInsight':
        this.appInsights.trackTrace({ message }, properties);
        break;
      case 'Console':
        this.writeToLog(message, LogLevel.Info, properties);
        break;
      case 'None':
        break;
    }
  }

  // Proposed revision of the logtrace method:
  // The AllowedEnvironments array restricts logging of the reqeust to the specified environmnt(s).
  // This is useful (for example) for logging specific data in dev and test, but not in prod.
  //------------------------------------------------------------------------------------------------
  // logTrace(message: string, properties: any, allowedEnvironments: EnvironmentLevel[] = []) {
  //   const env: EnvironmentLevel = environment.env.toLowerCase() as EnvironmentLevel;
  //   // const allowedEnvs = allowedEnvironments.map((e) => e.toLowerCase());
  //
  //   if (allowedEnvironments.includes(env) || allowedEnvironments.length === 0) {
  //     switch (environment.logTarget) {
  //       case 'AppInsight':
  //         this.appInsights.trackTrace({ message }, properties);
  //         break;
  //       case 'Console':
  //         this.writeToLog(message, LogLevel.Info, properties);
  //         break;
  //       case 'None':
  //         break;
  //     }
  //   }
  // }

  writeToLog(msg: string, level: LogLevel, params: any) {
    let entry: LogEntry = new LogEntry();
    entry.message = msg;
    entry.level = level;
    entry.extraInfo = params;
    entry.logWithDate = this.logWithDate;
    console.log(entry.buildLogString());
  }
}

export class LogEntry {
  // Public Properties
  entryDate: Date = new Date();
  message: string = '';
  level: LogLevel = LogLevel.Debug;
  extraInfo: any[] = [];
  logWithDate: boolean = true;

  buildLogString(): string {
    let ret: string = '';

    if (this.logWithDate) {
      const formattedDate = new Date().toLocaleDateString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZoneName: 'short',
      });
      ret = formattedDate + ' - ';
    }

    ret += 'Type1: ' + LogLevel[this.level];
    ret += ' - Message: ' + this.message;
    if (this.extraInfo.length) {
      ret += ' - Extra Info: ' + this.formatParams(this.extraInfo);
    }

    return ret;
  }

  private formatParams(params: any[]): string {
    let ret: string = params.join(',');

    // Is there at least one object in the array?
    if (params.some((p) => typeof p == 'object')) {
      ret = '';

      // Build comma-delimited string
      for (let item of params) {
        ret += JSON.stringify(item) + ',';
      }
    }

    return ret;
  }
}
