import { Component, ViewEncapsulation } from '@angular/core';
import { LoadingService } from '../../../services/loadingservice.service';

@Component({
  selector: 'app-loadingspinner',
  templateUrl: './loadingspinner.component.html',
  styleUrls: ['./loadingspinner.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class LoadingSpinnerComponent {
  constructor(public loadingservice: LoadingService) {}
}
