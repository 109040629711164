// *****
// Modularization
// Angular applications are modular and Angular has its own modularity system called NgModules.
// NgModules are containers for a cohesive block of code dedicated to an application domain, a workflow,
// or a closely related set of capabilities. They can contain components, service providers, and other code
// files whose scope is defined by the containing NgModule. They can import functionality that is exported
// from other NgModules, and export selected functionality for use by other NgModules.

// app.module.ts   - Global items are placed in the root module
// app.ux.module.ts - Shared UX modules (Angular Material and Custom Components)
// app.lazy.module.ts - Components (web pages)
// *****

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AppUxModule } from './app.ux.module';

import { AuthService } from './services/auth-service/auth.service';
import { FATrainService } from './services/fatrain.service';

import { AuthGuard } from './guards/auth.guard';
import { DirectAccessGuard } from './guards/auth.url.guard';

import { AuthComponent } from './auth/auth.component';
import { HeaderComponent } from './shared/ux-layout/header/header.component';
import PingComponent from './ping/ping.component';
import { SharedService } from './services/shared.service';

import { interceptors } from './interceptors/interceptors';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { LoadingSpinnerComponent } from './shared/ux-layout/loadingspinner/loadingspinner.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

@NgModule({
  //This command suppresses warning messages about angular components making it harder to debug when import is missing
  // 1. If 'xxxxxx' is an Angular component, then verify that it is part of this module.
  // 2. If 'xxxxxx' is a Web Component then add 'CUSTOM_ELEMENTS_SCHEMA' to the '@NgModule.schemas' of this component to suppress this message.
  //schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent, AuthComponent, HeaderComponent, PingComponent, LoadingSpinnerComponent],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule.forRoot(),
    BrowserAnimationsModule,
    OAuthModule.forRoot(),
    AppUxModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    { provide: OAuthStorage, useFactory: (): OAuthStorage => sessionStorage },
    AuthService,
    FATrainService,
    SharedService,
    AuthGuard,
    DirectAccessGuard,
    // IdleSessionService,  // Service currently uses providedIn :'root' so not required here
    // Add interceptors
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    interceptors,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
