export const environment = {
  env: 'qa',
  provider: 'https://fedauth.qa.alaskasworld.com',
  clientId: 'WPOw7rTFe5rrc1LdQF',
  apiKey: 'fb0fdfa32a5b4e54a10cd150e4c82e66',
  instrKey: '1ae72de6-6952-41ee-a47d-6b6533b35686',
  apiFTCURL: 'https://apis.qa.alaskaair.com/1/crew/training/lms/',
  logTarget: 'AppInsight', // Possible values : Console,AppInsight,None
  allowedGroups: 'as inflight training instructors,IT_VXQualEng,aad.crew.azureaccess,aad.crewqa.azureaccess,CFA Firefly',
  isProduction: false,
  idleInactiveMaxTime: 899, // Seconds of inactivity until idle state entered
  // idleWarningCountdown is very imprecise and the "ticks" slow down over time esp when in background
  idleWarningCountdown: 1, // Seconds until full time-out (warning/countdown)
  idleLoggingEnabled: false,
};
