import { TrainingStatus } from './shared/appEnums';

export const aagMessageConstants = {
  empty: '',
  RosterStateSuccess: 'Roster State Updated.',
  startClassSuccess: 'Class started',
  endClassSuccess: 'Class completed',
  retrieveClassError: 'Unable to retrieve classes. Please refresh and try again.',
  retrieveBaseError: 'Unable to retrieve bases. Please refresh and try again.',
  internalServerError: 'Something went wrong. Please refresh.',
  noClassSaved: 'No class saved. Please refresh and try again later.',
  noRosterSaved: 'No roster saved. Please refresh and try again later.',
  drillSavedSuccess: 'Drill outcome submitted.',
  exitDrill: 'Warning – the results of this drill have not been submitted. Do you want to exit without submitting?',
  overrideInstructor: '#InstructorName# is currently evaluating this study. Would you like to override and become the evaluator?',
  removeStudentInstructions: '#FullName# will be removed.   Would you like to proceed?',
  removeStudentConfirmation: '#FullName# was removed from this class.',
  removeStudentRejection: '#FullName# failed to be removed from this class.',
  noStudentsinFilter: 'No student found for the selected filter.',
  userNotAuthenticated: 'Access denied. If the issue persists report it to ITS service desk. Please close the browser to try again.',
  userNotAuthorized: 'Access denied. Please contact application owner to be granted access. ',
  userAuthentication: 'Login in progress.',
  userAuthorization: 'Access approval in progress.',
  userSessionStopped: 'Your session has been logged out manually or due to inactivity.',
  ssoProfileFailure: 'Failed to get user profile.',
  endClassConfirmation:
    'By ending class, you are confirming that all information is accurate and correct. You will be recorded as the instructor of record for this class. This action cannot be undone.',
  INVALID_IDLIST_BASEMESSAGE: 'Unable to generate list of employees.',
  INVALID_IDLIST_LENGTH: 'Max employeeids 50 allowed.',
  INVALID_IDLIST_CHARACTERS: 'ID List contains invalid characters.',
  INVALID_IDLIST_FORMAT: 'ID List is not formatted correctly. Must be 7 digit IDs separated by commas.',
  ADDSTUDENT_TEXTAREA_HELPTEXT: `Entry limited to 50 Ids`,
  ADDSTUDENT_TEXTAREA_PLACEHOLDER: 'Enter Peoplesoft Ids. Must be numbers separated by commas.',
  NAVCLASSROSTER_PROC_FAILED: 'Unable to process employee ids.',
  DATA_TOOLTIP_POI: 'Person of Interest (Trainee)',
  DATA_TOOLTIP_MGMT: 'Management',
  DATA_TOOLTIP_RQ: 'Requalification',
  ADDSTUDENT_ADDTOCLASS_UNHANDLED_ERROR: 'Unhandled Error (id b7454af0-57fb-4341-9ff6-60308364b098)',
  NAVROSTER_GETPERSONSUMMARIES_UNHANDLED_ERROR: 'Unhandled Error (id 5bb9f4be-da44-45cb-9503-f4182984742c)',
};

export const appConstants = {
  START_CLASS: 'START CLASS',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  STARTED: 'started',
  COMPLETE: 'complete',
  END_CLASS: 'END CLASS',
  REFRESH: 'REFRESH',
  IN_SESSION: 'In Session',
  SCHEDULED: 'Scheduled',
  PRESENT: 'PRESENT',
  ABSENT: 'ABSENT',
  SUCCESSFUL: 'Successful',
  UNSUCCESSFUL: 'Unsuccessful',
  DIALOG_NAVIGATION: 'NAVIGATION',
  DIALOG_RETURN_ROSTER: 'RETURN_ROSTER',
  DIALOG_MENU_CLICK: 'MENU_CLICK',
  DIALOG_WIDTH_MIN: '400px',
  DIALOG_WIDTH_MAX: '400px',
  MAX_ADDSTUDENT_INPUT_SIZE: 450,
  ROSTER_ADDSTUDENTS_ADD_LABEL: 'Add list to roster',
  ROSTER_ADDSTUDENTS_STARTOVER_LABEL: 'Start over',
  ROSTER_ADDSTUDENT_TITLE: 'Add students',
  ROSTER_ADDSTUDENT_POPUP_POSITION: { top: '72px' },
  ROSTER_ADDSTUDENT_POPUP_WIDTH: '650px',
  ROSTER_ADDSTUDENT_POPUP_WIDTH_MAX: '650px',
  //The vh setting sets % size relative to the viewport (browser window size)
  //While PX, EM, and REM are primarily used for font sizing, %, VW,
  //and VH are mostly used for margins, padding, spacing, and widths/heights.
  //To reiterate, VH stands for “viewport height”, which is the viewable screen's height.
  //100VH would represent 100% of the viewport's height, or the full height of the screen.
  ROSTER_ADDSTUDENT_POPUP_HEIGHT_MIN: '15vh',
  ROSTER_ADDSTUDENT_POPUP_HEIGHT_MAX: '70vh',
  ROSTER_PERSON_POPUP_WIDTH_MIN: '425px',
  ROSTER_PERSON_POPUP_WIDTH_MAX: '625px',
  ROSTER_PERSON_POPUP_HEIGHT_MIN: '325px',
  ROSTER_PERSON_POPUP_HEIGHT_MAX: '75vh',
  EXIT_DRILL: 'Exiting Drill Page',
  DRILL_NOT_SAVED: 'Drill Results Not Saved',
  DRILL_PROGRESS: 'Drill in Progress',
  REMOVE_STUDENT: 'Remove Student',
  RETURN_TO_ROSTER: 'Return to Roster',
  OK: 'OK',
  BASE_SELECTION_DEFAULT: 'All Bases',
  MENU_UI_CSSCLASS_CATEGORY_ITEM: 'category-item',
  MENU_UI_CSSCLASS_CATEGORY_STEP_ITEM: 'category-step-item',
  MENU_UI_CSSCLASS_CATEGORY_STEP_DRILL: 'category-step-drill',
  //States array is in sync with the enum TrainingStatus
  ROSTER_STATES: [
    { stateId: TrainingStatus.ANY, stateDesc: 'Status Filter' },
    { stateId: TrainingStatus.COMPLETE, stateDesc: 'Complete' },
    { stateId: TrainingStatus.SUCCESSFUL, stateDesc: 'Successful' },
    { stateId: TrainingStatus.UNSUCCESSFUL, stateDesc: 'Unsuccessful' },
    { stateId: TrainingStatus.ABSENT, stateDesc: 'Absent' },
    { stateId: TrainingStatus.INCOMPLETE, stateDesc: 'Incomplete' },
  ],
};

export const displayConstants = {
  TRAINEETYPETEXT_MGMT: 'MGMT',
  TRAINEETYPETEXT_OTHER: 'FA',
};

export const pageRouteConstants = {
  ROUTE_BVT: '/training/ui-bvt',
  ROUTE_CLASSES: '/training/classes',
  ROUTE_ROSTER: '/training/class-roster',
  ROUTE_DRILL: '/training/drills',
};
