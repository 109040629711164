// *****
// Routing
// Default routes
// *****

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { Routes, RouterModule, mapToCanActivate, mapToCanActivateChild, mapToCanMatch } from '@angular/router';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthComponent } from './auth/auth.component';
import PingComponent from './ping/ping.component';
import { AuthGuard } from './guards/auth.guard';

// 1.Lazy loading
//    - To lazy load Angular modules, use loadChildren (instead of component)
//      in your AppRoutingModule routes configuration
//    - In the lazy-loaded module's routing module, add a route for the component.
//      Also be sure to remove the ItemsModule from the AppModule.
// 2. Route Guards:
//    - canLoad only fires the first time the module is loaded.
//      canLoad prevents modules from being lazy-loaded if guard fails
//    - canActivate fires on every route change,
//    - canActviateChild can be used to prevent direct navigation between child URLs (of /training in this case)

const parentRoutes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    component: AuthComponent,
  },
  {
    path: 'ping',
    component: PingComponent,
  },
  {
    path: 'training',
    canMatch: mapToCanMatch([AuthGuard]),
    canActivate: mapToCanActivate([AuthGuard]),
    canActivateChild: mapToCanActivateChild([AuthGuard]),
    //canActivate: [BrowserGuard, AuthGuard, LoggedInUserGuard],
    loadChildren: () => import('./app.lazy.module').then((m) => m.AppLazyModule),
  },
  // Not required
  // {
  //   path: '**',
  //   redirectTo: 'training',
  //   canLoad: [AuthRouteGuard],
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(parentRoutes, {
      scrollPositionRestoration: 'enabled',
      // Debugging with enableTracing will all events to console
      //enableTracing: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  static forRoot(): ModuleWithProviders<AppRoutingModule> {
    return {
      ngModule: AppRoutingModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
      ],
    };
  }
}
