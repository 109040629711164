import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { IAAGDateTimeUtility } from '../utils/time-utils';
import { LogService } from './logging.service';
import { INavigationItem } from './shared.service';
import { ITrainingRoster, IRosterResponse } from '../models/shared/rosterResponse';
import { IClassResponse } from '../models/shared/classResponse';
import { IBaseLocationResponse } from '../models/shared/baseLocationResponse';
import { IClassRosterRequest } from '../models/shared/classRosterRequest';
import { IPersons } from '../models/shared/personResponse';
import { IRosterRequest } from '../models/shared/rosterRequest';
import { IDrillResponse } from '../models/shared/drillResponse';
import { IDrillDetailResponse } from '../models/shared/drillDetailResponse';
import { TrainingStatus } from '../models/shared/appEnums';
import { IDrillDetailRequest } from '../models/shared/drillDetailRequest';
import { IClassTrainingRequest } from '../models/shared/classTrainingRequest';
import { isDefined } from '../utils/utility-functions';
import { RosterNewRequest } from '../models/shared/rosterNewRequest';
import { RosterDeleteRequest } from '../models/shared/rosterDeleteRequest';

interface IaagHttpClientOptions {
  headers: HttpHeaders;
  body: string;
}

@Injectable({
  providedIn: 'root',
})
export class FATrainService {
  roster: ITrainingRoster[] = [];
  fatraingCollectURLTrainingBase: string;

  constructor(private http: HttpClient, private logService: LogService) {
    this.fatraingCollectURLTrainingBase = this.stripTrailingSlashes(environment.apiFTCURL);
  }

  //to pass apim keys
  httpOptions = {
    headers: new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.apiKey,
      'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept',
      'Content-Type': 'application/json',
    }),
  };

  private getHttpOptions(bodyRequest: any): IaagHttpClientOptions {
    const jsonData = JSON.stringify(bodyRequest);

    const options: IaagHttpClientOptions = {
      headers: this.httpOptions.headers,
      body: jsonData,
    };

    return options;
  }

  getValidBases(): Observable<Array<IBaseLocationResponse>> {
    const url = `${this.fatraingCollectURLTrainingBase}/training/bases`;
    return this.http.get<Array<IBaseLocationResponse>>(url, this.httpOptions).pipe(
      map((data) => {
        this.traceHandler(url, 'get', data);
        Object.assign(data, data);
        return data;
      }),
      catchError((err) => this.errorHandler(err, url, 'GET Valid Bases'))
    );
  }

  getStudents(navigationItem: INavigationItem): Observable<IRosterResponse> {
    const url = `${this.fatraingCollectURLTrainingBase}/courses/classes/scheduled?classDate=${navigationItem.classInfo.startDate}&tripIdentifier=${navigationItem.classInfo.tripIdentifier}&station=${navigationItem.classInfo.trainingStation}`;
    return this.http.get<IRosterResponse>(url, this.httpOptions).pipe(
      map((data) => {
        this.traceHandler(url, 'get', data);
        Object.assign(data, data);
        return data;
      }),
      catchError((err) => this.errorHandler(err, url, 'GET Students'))
    );
  }

  getStudentsByClassId(classId: number, status: TrainingStatus): Observable<IRosterResponse> {
    const url = `${this.fatraingCollectURLTrainingBase}/courses/classes/${classId}?status=${status}`;
    return this.http.get<IRosterResponse>(url, this.httpOptions).pipe(
      map((data) => {
        this.traceHandler(url, 'get', data);
        Object.assign(data, data);
        return data;
      }),
      catchError((err) => this.errorHandler(err, url, 'GET Students by Class ID'))
    );
  }

  getClassInfoList(startMonDt: IAAGDateTimeUtility, endMonDt: IAAGDateTimeUtility): Observable<IClassResponse> {
    let startDate = startMonDt.getAPIFormatDate();
    let endDate = endMonDt.getAPIFormatDate();

    const url = `${this.fatraingCollectURLTrainingBase}/courses/classes?trainingStartDate=${startDate}&trainingEndDate=${endDate}`;
    return this.http.get<IClassResponse>(url, this.httpOptions).pipe(
      map((data) => {
        this.traceHandler(url, 'get', data);
        Object.assign(data, data);
        return data;
      }),
      catchError((err) => this.errorHandler(err, url, 'GET ClassInfo'))
    );
  }

  getPerson(classId: number, personIdCsvList: string): Observable<IPersons> {
    let url = `${this.fatraingCollectURLTrainingBase}/courses/classes/${classId}/employees?employeeids=${personIdCsvList}`;

    return this.http.get<IPersons>(url, this.httpOptions).pipe(
      map((data) => {
        this.traceHandler(url, 'get', data);
        Object.assign(data, data);
        return data;
      }),
      catchError((err) => this.errorHandler(err, url, 'GET Person'))
    );
  }

  getStudentDrill(studentrosterID: number): Observable<IDrillResponse> {
    const url = `${this.fatraingCollectURLTrainingBase}/roster/students/${studentrosterID}/drills`;
    return this.http.get<IDrillResponse>(url, this.httpOptions).pipe(
      map((data) => {
        this.traceHandler(url, 'get', data);
        Object.assign(data, data);
        return data;
      }),
      catchError((err) => this.errorHandler(err, url, 'GET Student Drills'))
    );
  }

  getStudentDrillDetails(studentrosterID: number, drillId: number): Observable<IDrillDetailResponse> {
    const url = `${this.fatraingCollectURLTrainingBase}/roster/students/${studentrosterID}/drills/${drillId}`;
    return this.http.get<IDrillDetailResponse>(url, this.httpOptions).pipe(
      map((data) => {
        this.traceHandler(url, 'get', data);
        Object.assign(data, data);
        return data;
      }),
      catchError((err) => this.errorHandler(err, url, 'GET Student Drill Details'))
    );
  }

  AddStudentsToClass(classId: number, rosterNewRequest: RosterNewRequest): Observable<string> {
    const url = `${this.fatraingCollectURLTrainingBase}/courses/classes/${classId}/employees`;
    this.traceHandler(url, 'post', rosterNewRequest);
    const data = JSON.stringify(rosterNewRequest);

    return this.http
      .post<string>(url, data, this.httpOptions)
      .pipe(catchError((err) => this.errorHandler(err, url, 'Add New Student To Roster')));
  }

  RemoveStudentFromClass(studentrosterID: number, apiRequest: RosterDeleteRequest): Observable<string> {
    const url = `${this.fatraingCollectURLTrainingBase}/roster/students/${studentrosterID}`;

    this.traceHandler(url, 'delete', apiRequest);
    let options = this.getHttpOptions(apiRequest);

    return this.http
      .request<string>('delete', url, options)
      .pipe(catchError((err) => this.errorHandler(err, url, 'RemoveStudentFromClass')));
  }

  SaveNewClassRosterData(studentRoster: IClassRosterRequest): Observable<number> {
    let url = `${this.fatraingCollectURLTrainingBase}/courses/classes`;
    this.traceHandler(url, 'post', studentRoster);
    const data = JSON.stringify(studentRoster);
    return this.http.post<number>(url, data, this.httpOptions).pipe(catchError((err) => this.errorHandler(err, url, 'Add Roster Data')));
  }

  UpdateRosterData(studentRoster: IRosterRequest): Observable<boolean> {
    let url = `${this.fatraingCollectURLTrainingBase}/roster/students`;
    this.traceHandler(url, 'put', studentRoster);
    const data = JSON.stringify(studentRoster);
    return this.http.put<boolean>(url, data, this.httpOptions).pipe(catchError((err) => this.errorHandler(err, url, 'Update Roster Data')));
  }

  SaveNewStudentDrill(drillRequest: IDrillDetailRequest): Observable<boolean> {
    let url = `${this.fatraingCollectURLTrainingBase}/roster/students/drills`;
    this.traceHandler(url, 'post', drillRequest);
    const data = JSON.stringify(drillRequest);
    return this.http
      .post<boolean>(url, data, this.httpOptions)
      .pipe(catchError((err) => this.errorHandler(err, url, 'Add Student Drill Data')));
  }

  ResetStudentDrill(apiRequest: IDrillDetailRequest): Observable<boolean> {
    let url = `${this.fatraingCollectURLTrainingBase}/roster/students/drills`;
    this.traceHandler(url, 'delete', apiRequest);
    // Example of how to send data in the body of a delete request:
    // var data = JSON.stringify(drillRequest);
    // const options = {
    //   headers: new HttpHeaders({
    //     'Ocp-Apim-Subscription-Key': environment.apiKey,
    //     'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept',
    //     'Content-Type': 'application/json',
    //   }),
    //   body: data,
    // };

    const options = this.getHttpOptions(apiRequest);
    return this.http
      .request<boolean>('delete', url, options)
      .pipe(catchError((err) => this.errorHandler(err, url, 'Reset Student Drill Data')));
  }

  UpdateClass(trainingClass: IClassTrainingRequest): Observable<boolean> {
    let url = `${this.fatraingCollectURLTrainingBase}/courses/classes`;
    this.traceHandler(url, 'put', trainingClass);
    const data = JSON.stringify(trainingClass);
    return this.http.put<boolean>(url, data, this.httpOptions).pipe(catchError((err) => this.errorHandler(err, url, 'Update Class')));
  }

  /*
   * Check domain and verify ending slash
   */
  private stripTrailingSlashes(str: string): string {
    // Single or consecutive trailing slashes:

    let i = str.length;
    while (str[--i] === '/');
    return str.slice(0, i + 1);
  }

  private getHeader(httpHeaders: HttpHeaders, headerSearchKey: string): string | null {
    let headerKeys = httpHeaders.keys();

    for (let headerKey of headerKeys) {
      let headerValue = httpHeaders.get(headerKey) ?? '';
      // Debug Code
      // console.log(`${headerKey} : ${headerValue}`);

      let value1 = headerSearchKey.toLowerCase();
      let value2 = headerKey.toLowerCase();

      if (value1 === value2) {
        return headerValue;
      }
    }

    return null;
  }

  /*
   * This is a common error handler to log in app insights
   * operation example format: GET Valid Bases
   * body: send it for POST, PUT, DELETE Operations
   * @angular/common/http > HttpErrorResponse
   */
  // private errorHandler<T>(err: any, url?: string, operation = 'operation', reqBody?: any) {
  private errorHandler<T>(err: HttpErrorResponse, url?: string, operation = 'operation', reqBody?: any) {
    let correlationID: string | null = null;

    if (err.headers) {
      let headerValue = this.getHeader(err.headers, 'x-correlation-id');
      // Debug Code
      // console.log(`header correlationID: ${headerValue}`);

      correlationID = isDefined(headerValue) ? `, CorrelationID: ${headerValue}` : '';
      // Debug Code
      // console.log(`calculated correlationID: ${correlationID}`);
    }

    let errorMessage = JSON.stringify(err.error);
    let loggableErr = {} as Error;
    loggableErr.stack = errorMessage;

    let traceMessage: string = `Request: ${operation}-${url}${correlationID}`;
    //trap body as json string to add to trace in app insights
    if (isDefined(reqBody)) {
      traceMessage = traceMessage.concat(`, Body: ${JSON.stringify(reqBody)}`);
    }

    // Debug Code
    //log trace in app insights
    this.logService.logTrace(traceMessage, { url: url });
    // Debug Code
    //log error in app insights
    this.logService.logException(loggableErr, 1);

    return throwError(() => errorMessage); //err.error);
  }

  private traceHandler(url: string, urlMethod: string, data: any) {
    if (environment.logTarget != 'None') {
      const urlName = `Url(${urlMethod}): ${url}`;
      //Debug Code:
      // this.logService.logTrace(urlName, [{ RespData: JSON.stringify(data) }]);
      this.logService.logTrace(urlName, [{ RespData: JSON.stringify('redacted') }]);
    }
  }
}

//DEADCODE
//DEADCODE
//DEADCODE
//DEADCODE
//DEADCODE
//Determine if this URL is APIM or direct URL
// private IsAPIMRoute(url: string): boolean {
//   if (url.search('apis.') == -1) {
//     return false; //"Does not contain"
//   } else {
//     return true;
//   }
// }

// //APIMs do not have CONTROLLER in the route.  A rewrite rule maps from API to Webservice Route
// //APIM route:       ==> https://apis.test.alaskaair.com/1/crew/training/lms/classes
// //Webservice route: ==> https://fatraincollect-test-api-westus2.azurewebsites.net/TRAINING/classes
// //Add appropriate controller
// private ModifyRouteWithTrainingController(url: string): string {
//   if (this.IsAPIMRoute(url)) {
//     return url;
//   } else {
//     let lastChar = url.charAt(url.length - 1);
//     let reformatUrl = lastChar == '/' ? url.concat('training/') : url.concat('/training/');
//     return reformatUrl; //Append controller to route
//   }
// }
