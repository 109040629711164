// This service is used for passing data between different components.
// Review again before Go-Live , if the service name is appropriate. 04/28/2021.
import { Injectable } from '@angular/core';
import { IClassItem } from '../models/shared/classResponse';
import { IInstructor } from '../models/shared/instructorParms';
import { ITrainingRoster } from '../models/shared/rosterResponse';

@Injectable()
export class SharedService {
  navigationItem: INavigationItem;

  private person: IInstructor;
  private userLoggedOut: boolean = false;

  setUserInfo(person: IInstructor) {
    this.person = person;
  }

  getUserInfo(): IInstructor {
    return this.person;
  }
}

export interface INavigationItem {
  classInfo: IClassItem;
  studentRoster: ITrainingRoster;
  classTitle: string;
  isDrillUpdated: boolean;
}

//Code Snippets....
// While @Timar's answer works perfectly for null default values (what was asked for),
// here another easy solution which allows other default values: Define an option interface
// as well as an according constant containing the defaults; in the constructor use the spread
// operator to set the options member variable
//https://stackoverflow.com/questions/46235436/angular-whats-meaning-of-three-dots-in-ngrx
//
// export class ClassX {
//     private options: IXOptions;
//
//     constructor(XOptions: IXOptions) {
//         this.options = { ...XDefaults, ...XOptions };
//     }
// }
