import { EventEmitter, Injectable, OnDestroy, Output } from '@angular/core';
import { Idle, InterruptSource } from '@ng-idle/core';
import { Interrupt } from '@ng-idle/core/lib/interrupt';
import { Keepalive } from '@ng-idle/keepalive';
import { LogService } from './logging.service';
import { environment } from 'src/environments/environment';

// The IdleSession Service acts as a wrapper for @ng-idle.
// This service is intended to abstract @ng-idle so that
//  - unit testing is a bit easier
//  - @ng-idle could be replaced more eeasily if support for it is dropped
//  - faulty @ng-idle methods could be pathced individually here
//  - custom functionality outside scope of ng-idle could be added on top of @ng-idle
// @ngidle documentation links:
// https://www.npmjs.com/package/@ng-idle/core
// https://github.com/moribvndvs/ng2-idle#readme

export interface IIdleSessionService {
  clearInterrupts(): void;
  getIdle(): number;
  getInterrupts(): Interrupt[];
  getTimeout(): number;
  ngOnDestroy(): void;
  setIdle(seconds: number): number;
  setInterrupts(sources: InterruptSource[]): Interrupt[];
  setTimeout(seconds: number): number;
  stop(): void;
  timeout(): void;
  watch(skipExpiry?: boolean | undefined): void;
  // *** The following available methods from the wrapped @ng-idle service
  // *** are not in use at this time:
  //  getAutoResume(): AutoResume;
  //  getKeepaliveEnabled(): boolean;
  //  interrupt(force?: boolean | undefined, eventArgs?: any): void;
  //  isIdling(): boolean;
  //  isRunning(): boolean;
  //  setAutoResume(value: AutoResume): AutoResume;
  //  setIdleIntervalOutsideOfZone(watchFn: () => void, frequency: number): void;
  //  setIdleName(key: string): void;
  //  setKeepaliveEnabled(value: boolean): boolean;
}

@Injectable({
  providedIn: 'root', // Makes this a singleton: only ONE idle session
})
export class IdleSessionService implements IIdleSessionService, OnDestroy {
  public isLoggingEnabled: boolean = false;
  // public allowedlogLevels: EnvironmentLevel[] = [EnvironmentLevel.dev, EnvironmentLevel.test];
  private ngIdle: Idle;
  private ngIdleKeepAlive: Keepalive;

  // Emitter props
  @Output() onIdleEnd: EventEmitter<any>;
  @Output() onIdleStart: EventEmitter<any>;
  @Output() onInterrupt: EventEmitter<any>;
  @Output() onTimeout: EventEmitter<number>;
  @Output() onTimeoutWarning: EventEmitter<number>;

  constructor(public idle: Idle, private keepalive: Keepalive, private logService: LogService) {
    this.ngIdle = idle;
    this.ngIdleKeepAlive = keepalive;

    // Assign emitters here to pass through
    this.onIdleEnd = this.ngIdle.onIdleEnd;
    this.onIdleStart = this.ngIdle.onIdleStart;
    this.onTimeout = this.ngIdle.onTimeout;
    this.onTimeoutWarning = this.ngIdle.onTimeoutWarning;
    // not in use: this.onInterrupt = this.ngIdle.onInterrupt;

    // Subscribe to events for logging (need a way to toggle this on/off? More parameters?)
  }

  public enableLogging(enable: boolean): void {
    this.isLoggingEnabled = enable;
    if (enable) {
      console.log('IdleSessionService(): Logging enabled: environment.idleLoggingEnabled = ' + environment.idleLoggingEnabled);
    }
    this.logevents(enable);
  }

  public clearInterrupts(): void {
    this.ngIdle.clearInterrupts();
  }

  public getIdle(): number {
    return this.ngIdle.getIdle();
  }

  public getInterrupts(): Interrupt[] {
    return this.ngIdle.getInterrupts();
  }

  public getTimeout(): number {
    return this.ngIdle.getTimeout();
  }

  public ngOnDestroy(): void {
    this.idle.ngOnDestroy();
  }

  public setIdle(seconds: number): number {
    if (this.isLoggingEnabled) {
      let message = 'IdleSessionService(): Set Idle to ' + seconds + ' seconds';
      this.logService.logTrace(message, {});
    }

    return this.ngIdle.setIdle(seconds);
  }

  public setInterrupts(sources: InterruptSource[]): Interrupt[] {
    return this.idle.setInterrupts(sources);
  }

  public setTimeout(seconds: number): number {
    if (this.isLoggingEnabled) {
      let message = 'IdleSessionService(): Set Timeout (Warning) to ' + seconds + ' seconds';
      this.logService.logTrace(message, {});
    }

    return this.ngIdle.setTimeout(seconds);
  }

  public stop(): void {
    if (this.isLoggingEnabled) {
      let message = 'IdleSessionService(): Stop Idle';
      this.logService.logTrace(message, {});
    }

    this.idle.stop();
  }

  public timeout(): void {
    if (this.isLoggingEnabled) {
      let message = 'IdleSessionService(): timeout forced';
      this.logService.logTrace(message, {});
    }

    this.idle.timeout();
  }

  public watch(skipExpiry?: boolean | undefined): void {
    if (this.isLoggingEnabled) {
      let message = 'IdleSessionService(): Watch (Start) Idle';
      this.logService.logTrace(message, {});
    }

    this.idle.watch(skipExpiry);
  }

  private logevents(enabled: boolean) {
    if (!enabled) {
      return;
    }

    // When idle ends, reset the watch / restart the idle timer
    this.idle.onIdleEnd.subscribe(() => {
      this.logService.logTrace('IdleSessionService(): IdleEnd (activity detected)', {});
    });

    this.idle.onTimeout.subscribe(() => {
      this.logService.logTrace('IdleSessionService(): IdleTimeOut', {});
    });

    this.idle.onIdleStart.subscribe(() => {
      this.logService.logTrace('IdleSessionService(): IdleStart', {});
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      let message = 'IdleSessionService(): Session will time out in ' + countdown + ' seconds';
      this.logService.logTrace(message, {});
    });
  }

  // *** The following available methods from the wrapped @ng-idle service
  // *** are not in use at this time:
  //
  //  public getAutoResume(): AutoResume {
  //   return this.ngIdle.getAutoResume();
  // }
  //
  //  public getKeepaliveEnabled(): boolean {
  //   return this.idle.getKeepaliveEnabled();
  // }
  //
  //  public interrupt(force?: boolean | undefined, eventArgs?: any): void {
  //   return this.idle.interrupt(force, eventArgs);
  //}
  //
  //  public isIdling(): boolean {
  //    return this.idle.isIdling();
  //  }
  //
  //  public isRunning(): boolean {
  //   return this.idle.isRunning();
  // }
  //
  //  public setAutoResume(value: AutoResume): AutoResume {
  //   return this.idle.setAutoResume(value);
  // }
  //
  // Not in use :  public setIdleIntervalOutsideOfZone(watchFn: () => void, frequency: number): void {
  //  return this.idle.setIdleIntervalOutsideOfZone(watchFn, frequency);
  //}
  //
  //  public setIdleName(key: string): void {
  //    this.idle.setIdleName(key);
  // }
  //
  //  public setKeepaliveEnabled(value: boolean): boolean {
  //    return this.idle.setKeepaliveEnabled(value);
  //    return false;
  //  }
}
