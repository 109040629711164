// EXAMPLE CODE
// import { HTTP_INTERCEPTORS } from '@angular/common/http';
// import { ApiInterceptor } from './api.interceptor';
// import { AuthInterceptor } from './auth.interceptor';
//
// --- READ THIS BEFORE ADDING AN INTERCEPTOR ---
// Interceptors are outside-in, meaning when a request is made the first interceptor in the array below
// is hit first, when the response come back, that same interceptor is hit last
// this means we always want the ApiInterceptor last, the very first thing we want to know when a request comes
// back from the API is, "Is this user authenticated?", if they aren't we don't take any additional action, we kick
// them out of the application and force them to re-authenticate
// --- READ THIS BEFORE ADDING AN INTERCEPTOR ---
//
/** Http interceptor providers in outside-in order */
export const interceptors = [
  // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
];
