import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { aagMessageConstants } from '../models/ui-constants';
import { AuthenticationType } from '../models/ui-enums';
import { AuthService } from '../services/auth-service/auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit, OnDestroy {
  /**
   * Indicates if the authentication process is ongoing, if this is false, something has gone wrong and authentication failed
   */
  private subscriptions: Subscription[];
  public isAuthenticating: boolean; // this is used for testing...
  public authMessageTitle: string;
  /**
   * Subject that will emit when the components OnDestroy lifecycle event is triggered
   */
  private onDestroy$ = new Subject<void>();

  constructor(private authService: AuthService, private router: Router) {
    this.authMessageTitle = '';
    this.isAuthenticating = true;
    this.subscriptions = [];
  }

  public ngOnInit() {
    this.subscriptions.push(
      this.authService.onTokenReceived$.subscribe(() => {
        this.router.navigateByUrl('training');
      })
    );

    this.subscriptions.push(
      this.authService.onError$.subscribe((userAuthorizeCheck) => {
        this.authMessageTitle =
          userAuthorizeCheck == AuthenticationType.NotAuthorized
            ? aagMessageConstants.userNotAuthorized
            : aagMessageConstants.userNotAuthenticated;
        this.isAuthenticating = false;
        // reroute to auth page on error
        // navigateByUrl intentionally strips the auth token from the url
        this.router.navigateByUrl('auth');
      })
    );

    this.authService.isAuthorized$.subscribe((result) => {
      if (result === AuthenticationType.Success) {
        this.authMessageTitle = '';
        this.router.navigateByUrl('training');
      } else {
        // *** stay on this page
      }
    });

    let validAuthenticationToken = this.authService.hasValidAuthenticationToken();

    if (this.authService.hasUserLoggedOut()) {
      this.authMessageTitle = aagMessageConstants.userSessionStopped;
    } else {
      this.authMessageTitle = validAuthenticationToken ? aagMessageConstants.userAuthorization : aagMessageConstants.userAuthentication;
    }
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
