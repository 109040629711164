export interface IMessageBanner {
  Content: string;
  Type: MessageType;
  isSet(): boolean;
}

export enum MessageType {
  Error = 1,
  Warning,
  Success,
  Information,
  Dialog,
  NavigationDialog,
  None,
}

export class MessageBanner implements IMessageBanner {
  Content: string;
  Type: MessageType;
  constructor(content: string, type: MessageType) {
    this.Content = content;
    this.Type = type;
  }
  isSet() {
    if (this.Type == MessageType.None || this.Type == MessageType.Dialog || this.Type == MessageType.NavigationDialog) {
      this.Content = '';
    }

    return this.Content != '';
  }
}
