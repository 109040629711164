import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { ReplaySubject, Subject, filter } from 'rxjs';
import { aagMessageConstants } from 'src/app/models/ui-constants';
import { AuthenticationType } from 'src/app/models/ui-enums';
import { environment } from 'src/environments/environment';
import { AAGUserInfo } from '../../models/aag-user-info';
import { authConfig } from '../../models/auth.config';
import { LogService } from '../logging.service';

export interface IAuthService {
  /**
   * emits when a new token is received
   */
  onTokenReceived$: Subject<void>;
  /**
   * emits when an error occurs
   */
  onError$: Subject<number>;
  /**
   * emits when a user is authorized successfully
   */
  isAuthorized$: Subject<number>;
  /**
   * Emits openid user info once the data is returned from the openid endpoint on the FedAuth server
   */
  userInfo$: ReplaySubject<AAGUserInfo>;
  /**
   * Initialize authentication, this will either get the users current valid access token or redirect them to the auth sign in
   */
  initAuth(): void;
  /**
   * returns the current access_token
   */
  getAuthenticationToken(): string;
  /**
   * checks if the current access_token is valid
   */
  hasValidAuthenticationToken(): boolean;
  /* bool indicating loggedout status
   */
}

/**
 * Provides a wrapper around a third party OAuth2 library and provides convenience methods for the authentication process
 */
@Injectable()
export class AuthService implements IAuthService {
  public onTokenReceived$: Subject<void>;
  public onError$: Subject<AuthenticationType>;
  public isAuthorized$: Subject<AuthenticationType>;
  public userInfo$: ReplaySubject<AAGUserInfo>;
  public isUnitTest: boolean = false;
  public isAuthorizedUser: boolean = false;

  // *******************************************************************
  // THIS IS FOR TESTING/DEBUGGING ONLY: *** MUST be false to deploy ***
  // Alternatively change the allowedGroups value in environments/environments.dev.ts to something invalid
  private forceAuthorizationFailure: boolean = false;
  userLoggedOut: boolean;
  // *******************************************************************

  constructor(private oAuthService: OAuthService, private logService: LogService) {
    this.onTokenReceived$ = new Subject<void>();
    this.onError$ = new Subject<AuthenticationType>();
    this.isAuthorized$ = new Subject<AuthenticationType>();
    this.userInfo$ = new ReplaySubject<AAGUserInfo>(1);
  }

  public initAuth(): void {
    this.subscribeToOAuthEvents();
    this.configureOAuth();
    this.oAuthService
      .loadDiscoveryDocumentAndTryLogin()
      .then(() => {
        // Execute auth code flow if no authtentication token, otherwise authorize
        if (!this.hasValidAuthenticationToken()) {
          this.oAuthService.initCodeFlow();
        } else {
          this.getAuthorization();
          // use this to check value: let accessTokenExpiration = new Date(this.oAuthService.getAccessTokenExpiration());
        }
      })
      .catch((error: Error) => {
        if (error) {
          let errMsg = 'Authentication Error.  loadDiscoveryDocumentAndLogin: ' + JSON.stringify(error);
          this.logService.logTrace(errMsg, '');
        }
        this.onError$.next(AuthenticationType.None);
      });
  }

  public configureOAuth() {
    this.oAuthService.configure(authConfig);
    // Custom parameter required so PING Federate will "prompt" user for login
    this.oAuthService.customQueryParams = {
      prompt: 'login',
    };
  }

  public getAuthenticationToken(): string {
    // Check token with this: let accessTokenExpiration = new Date(this.oAuthService.getAccessTokenExpiration());
    return this.oAuthService.getAccessToken();
  }

  public hasValidAuthenticationToken(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }

  /**
   * Subscribe to OAuthService events and then translate and emit them from the Auth Service
   */
  private subscribeToOAuthEvents(): void {
    this.oAuthService.events.subscribe((event) => {
      if (event.type === 'token_received') {
        this.onTokenReceived$.next();
      }
      if (event.type.includes('_error')) {
        let errMsg = 'Authentication Error.  Event Type Details: ' + event.type;
        this.logService.logTrace(errMsg, '');
        this.onError$.next(AuthenticationType.NotAuthenticated);
      }
    });
  }

  /**
   * Auth related tasks that should happen after we have a valid authentication token
   */
  public getAuthorization() {
    this.oAuthService
      .loadUserProfile()
      .then((userInfo) => {
        let userData = userInfo as AAGUserInfo;

        if (userData.info) {
          // Data Structure change between "angular-oauth2-oidc": "^10.0.3" ==> "angular-oauth2-oidc": "^12.1.0"
          //  Data is wrapped in info level (partial child data included)
          // {
          //   "info": {
          //     "sub": "theo.fredericks@bogusair.com",
          //     "groups": [
          //       "GHF Word Plan 2 License Users",
          //       "JKD License Users",
          //     ],
          //   }
          // }
          userData = userData.info as AAGUserInfo;
        }

        if (this.isUserAuthorized(userData)) {
          this.isAuthorized$.next(AuthenticationType.Success);
          this.isAuthorizedUser = true;
        } else {
          // The route guard process subscribes to onError$ & redirects
          this.onError$.next(AuthenticationType.NotAuthorized);
          this.isAuthorizedUser = false;
        }

        this.userInfo$.next(userData);
      })
      .catch(() => this.logService.logException(new Error(aagMessageConstants.ssoProfileFailure)));
  }

  /**
   * Checks if the user is authorized to access the application
   * @param userProfile
   * @returns boolean
   */
  private isUserAuthorized(userProfile: AAGUserInfo): boolean {
    if (this.isUnitTest) {
      return true;
    }

    let isAuthorized: boolean = false;

    // forceAuthorizationFailure IS FOR TESTING/DEBUGGING ONLY: Set private variable at top.
    if (this.forceAuthorizationFailure) {
      this.isAuthorized$.next(AuthenticationType.NotAuthorized);
      isAuthorized = false;
      return isAuthorized;
    }
    // *******************************************************************

    //String comparisons are case sensitive.  Force both to lower case before comparing
    let allowedGroups = environment.allowedGroups?.toLowerCase();

    let userprofileGroups = userProfile.groups;
    if (userprofileGroups) {
      let allowedGroupsArray = allowedGroups.split(',');
      // TODO: This matching may have unexpected side-effects for some partial patches.
      userprofileGroups.forEach((value: string) => {
        allowedGroupsArray.forEach((group) => {
          if (value.toLowerCase().includes(group)) {
            isAuthorized = true;
          }
        });
      });
    }

    //Search for app insights custom event
    //CUSTOM EVENT: "User is denied access. Not authorized"
    if (!isAuthorized) {
      let fullName: string = userProfile.given_name + ' ' + userProfile.family_name;
      let errMsg = 'User is denied access. Not authorized';
      let userid: string = userProfile.emplid ?? 'unknown';
      let properties = {
        info: 'UI Exception',
        itemType: 'Authorization',
        userId: userid,
        UserName: fullName,
        isProduction: environment.isProduction,
        environment: environment.env,
        //allowedSecurityGroups: allowedGroups,
        //userSecurityGroups: userSecurityGroups,
      };

      this.logService.logEvent(errMsg, properties);
    }

    return isAuthorized;
  }

  // public clearLoggedOutStatus(): void {
  //   // Important: THis method does NOT do any authentication or authorization.
  //   // It clears session data and sets "logged in" tracking status
  //   this.clearUserLoggedOut();
  // }

  // public setloggedOutStatus(): void {
  //   // This method should not be called if user is not logged in.
  //   // It will clear session variables and set "logged out" tracking status
  //   this.setUserLoggedOut();
  //   //}
  // }

  // This is NOT used for OAuth2 authorization
  // It tracks whenter use manually logged out and prevents logging back in
  public setUserLoggedOut() {
    sessionStorage.clear(); // claar all session storage
    sessionStorage.setItem('userLoggedOut', 'true');
    this.userLoggedOut = true;
  }

  // This is NOT used for OAuth2 authorization
  // It clears the session variable that prevents user from logging back in
  public clearUserLoggedOut() {
    sessionStorage.clear(); // claar all session storage
    sessionStorage.setItem('userLoggedOut', 'false');
    this.userLoggedOut = false;
  }

  // See comment on setUserLoggedOut()
  public hasUserLoggedOut(): boolean {
    this.userLoggedOut = sessionStorage.getItem('userLoggedOut') == 'true';
    return this.userLoggedOut;
  }
}

//2021/07/27 Colton Williams
// We are using localstorage (instead of session storage), the actual oAuthService (the third party library) manages this state instead of the AuthService (our own code)
// the oAuthService hydrates its own state based on what is in localstorage and then our service just asks the third part library "do they have a valid token"? that token might have just been retrieved via fedauth or it could be in localstorage from 4 hours ago, we don't care
// the local storage logic is setup in src/app/app.module.ts:18
// localstorage lasts longer than sessionstoragesessionstorage: the browser tab closes, the data is gone (and if a new tab is opened with the same application, it does not have access to the original tabs sessionstorage)
// localstorage: persists even after the browser is closed and can be shared across tabs

//2023/05/18 Brian Minnis
// Revised routing and route guards to prevent direct access to child routes. Renamed various properties and methods to be more descriptive.

//2023/09/06 Brian Minnis
// New functionality added for user logout and login.

//2024/02/26 Sebastian Kuc
// Upgrade to from Angular 15 -> 17, revise route guards to use CanMatch and functional guards due to CanLoad deprecation. 
