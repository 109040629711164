import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { IInstructor } from '../../../models/shared/instructorParms';
import { SharedService } from '../../../services/shared.service';
import packageInfo from '../../../../../package.json';
import { AuthService } from '../../../services/auth-service/auth.service';
import { isDefined } from '../../../utils/utility-functions';
import { Observable } from 'rxjs';
import { AAGUserInfo } from '../../../models/aag-user-info';
import { Router } from '@angular/router';
import { IdleSessionService } from '../../../services/idle-session.service';
import { LogService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  displayRegion: boolean = false;
  region: string = 'System';
  version: string = packageInfo.version;

  firstName?: string = 'firstName';
  lastName: string = 'lastName';
  person: IInstructor;

  /**
   * An observable that emits the domain_username from the auth service
   */
  person$: Observable<AAGUserInfo>;
  public userLoggedOut: boolean = this.authService.hasUserLoggedOut();
  displayLoginTimeout: boolean;
  loginTimeoutMinutes: number;
  loginTimeoutSeconds: number;
  loginLinkText: string;

  constructor(
    private sharedService: SharedService,
    private authService: AuthService,
    private router: Router,
    private idleSessionService: IdleSessionService,
    private logService: LogService
  ) {}

  ngOnInit() {
    this.setDisplayForEnvironment(environment);

    this.getUserInfo();

    this.idleSessionService.onTimeout.subscribe(() => {
      this.manageLoginText();
    });

    this.authService.onError$.subscribe((userAuthorizeCheck) => {
      this.manageUnauthorizedState();
      this.manageLoginText();
    });

    this.loginLinkText = this.userLoggedOut ? 'Login' : 'Logout';
  }

  public setDisplayForEnvironment(environment: any): void {
    if (environment.env.toLowerCase() !== 'prod') {
      this.displayRegion = true;
      this.region = `${environment.env.toUpperCase()} ${this.region}`;

      this.displayLoginTimeout = true;
      this.loginTimeoutMinutes = Math.floor((environment.idleInactiveMaxTime + environment.idleWarningCountdown) / 60);
      this.loginTimeoutSeconds = (environment.idleInactiveMaxTime + environment.idleWarningCountdown) % 60;
    }
  }

  public getUserInfo(): void {
    this.person = {} as IInstructor;
    this.clearPersonDisplayData();
    this.authService.userInfo$.subscribe((x) => {
      if (isDefined(x)) {
        let personInfo: IInstructor = {
          firstName: x.given_name ?? '',
          lastName: x.family_name ?? '',
          employeeId: x.emplid ?? '',
          middleName: '',
        };
        this.person = personInfo;
        this.sharedService.setUserInfo(this.person);
      }
    });
  }

  public manageLoginText() {
    if (!this.authService.isAuthorizedUser) {
      this.loginLinkText = '';
      return;
    }
    this.loginLinkText = this.authService.hasUserLoggedOut() || !this.authService.isAuthorizedUser ? 'Login' : 'Logout';
    this.clearPersonDisplayData();
  }

  public manageLoginState() {
    if (this.authService.hasUserLoggedOut() || !this.authService.isAuthorizedUser) {
      this.logIn();
    } else {
      this.logOut();
      this.clearPersonDisplayData();
    }

    this.manageLoginText();
  }

  public logIn() {
    this.authService.clearUserLoggedOut();
    // No need to call this.idleSessionService.watch(true); here.
    //   The reload calls app.coponent which takes care of it.
    // Reload the current page, so authentication will be checked
    this.locationreload();
  }

  public logOut() {
    this.authService.setUserLoggedOut();
    // this prevents uneeded idle timeout warning events from firing
    this.idleSessionService.stop();
    // route back to the 'auth' page for logged out handling
    this.router.navigateByUrl('auth');
  }

  public manageUnauthorizedState() {
    this.idleSessionService.stop();
  }

  public clearPersonDisplayData(): void {
    this.person.firstName = '';
    this.person.lastName = '';
    this.person.employeeId = '';
    this.person.middleName = '';
    this.person.employeeId = '';
  }

  public locationreload() {
    // To reload the entire page from the server
    // abstracting it to a function allows for easier testing
    // Avoids page reloaded error in tests
    location.reload();
  }
}
