<div class="container">
  <div>
    <app-header></app-header>
  </div>

  <div>
    <router-outlet></router-outlet>
  </div>
  <app-loadingspinner></app-loadingspinner>
</div>
