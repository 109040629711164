export enum AuthenticationType {
  Success = 1,
  None = 2,
  NotAuthorized = 3,
  NotAuthenticated = 4,
}

export enum Color {
  NONE = 0,
  GREEN = 1,
  RED = 2,
  ORANGE = 3,
  BLUE = 4,
  GREY = 5,
  NEMO = 6,
  TURQUOISE = 7,
}

export enum ProcessAction {
  NONE = 0,
  SUCCESS = 1,
  CANCEL = 2,
  STARTOVER = 3,
  FAILURE = 9,
}
