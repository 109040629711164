import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from './services/auth-service/auth.service';
import { SharedService } from './services/shared.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { IdleSessionService } from './services/idle-session.service';
import { DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { LogService } from './services/logging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'FATrainCollectUI';
  isLoading: boolean;
  // logout idle variables
  idleActionMessage: string = 'Initialized';

  constructor(
    private authService: AuthService,
    private sharedService: SharedService,
    private router: Router,
    private idleSessionService: IdleSessionService,
    private logService: LogService
  ) {}

  ngOnInit() {
    //--- Idle Session Setup ---
    // sets configuration for idle timeouts
    this.IdleConfiguration();
    // sets the idle session subscriptions
    this.IdleSubscriptions();

    // Start the authentication process
    // Only run the Auth processes if the user is not logged out at the site level
    // NOTE: This is not the same as logging out of OAuth. AAG OAuth via Ping Federate usses an SSO schem that keeps users logged in for at leas 120 minutes.
    if (!this.authService.hasUserLoggedOut()) {
      this.authService.initAuth();
      this.idleSessionService.watch();
    }
  }

  // Sets the configuration for the idle session
  private IdleConfiguration() {
    // enable idle session service debug logging based on environment
    this.idleSessionService.enableLogging(environment.idleLoggingEnabled);
    // sets the idle session timeout
    this.idleSessionService.setIdle(+environment.idleInactiveMaxTime);
    // sets a countdown period added to the timeout
    this.idleSessionService.setTimeout(+environment.idleWarningCountdown);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idleSessionService.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  }

  // Create all subscriptions for the idle session
  private IdleSubscriptions() {
    // When idle ends, reset the watch / restart the idle timer
    this.idleSessionService.onIdleEnd.subscribe(() => {
      this.idleSessionService.watch();
    });

    // When idle fully timesout, log the user out
    this.idleSessionService.onTimeout.subscribe(() => {
      this.authService.setUserLoggedOut();
      // A "stop" here is not needed and can cause issues with countdown & idle.
      // Idle session service is destroyed on logout
      //-- this.idleSessionService.stop();
      this.router.navigateByUrl('auth');
    });
  }

  ngOnDestroy() {
    // add any idle cleanup inside this method
    this.idleSessionService.ngOnDestroy();
  }
}
