// import { BrowserModule } from '@angular/platform-browser';
//If you do import BrowserModule into a lazy loaded feature module, Angular returns an error telling you to use CommonModule instead.
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatGridListModule } from '@angular/material/grid-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';


import BoxComponent from './shared/ux-elements/box.component';
import ButtonComponent from './shared/ux-elements/button.component';
import ChipComponent from './shared/ux-elements/chip.component';
import IconButtonComponent from './shared/ux-elements/iconbutton.component';
import StateButtonComponent from './shared/ux-elements/statebutton.component';
import StateIconComponent from './shared/ux-elements/stateicon.component';
import ProgressStateIconComponent from './shared/ux-elements/progressstateicon/progressstateicon.component';

@NgModule({
  //This command suppresses warning messages about angular components making it harder to debug when import is missing
  // 1. If 'xxxxxx' is an Angular component, then verify that it is part of this module.
  // 2. If 'xxxxxx' is a Web Component then add 'CUSTOM_ELEMENTS_SCHEMA' to the '@NgModule.schemas' of this component to suppress this message.
  //schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    ButtonComponent,
    StateButtonComponent,
    StateIconComponent,
    IconButtonComponent,
    ChipComponent,
    BoxComponent,
    ProgressStateIconComponent,
  ],
  imports: [
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatSelectModule,
    MatSidenavModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    CommonModule,
  ],
  // providers: [
  // ],
  // bootstrap: [AppComponent],
  exports: [
    BoxComponent,
    ButtonComponent,
    ChipComponent,
    IconButtonComponent,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatSelectModule,
    MatSidenavModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    ProgressStateIconComponent,
    StateButtonComponent,
    StateIconComponent,
  ],
})
export class AppUxModule {}
